import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Modal, Input, Radio, Select } from 'antd';
import { layout } from './configs';

const Index = forwardRef(
  ({ form, item, cashier, onCancel, title, visible, onOk, confirmLoading, ruleList }, ref) => {
    const [boxType, setBoxType] = useState(item?.type ? `${item.type}` : '2');
    const { getFieldDecorator, getFieldValue } = form;
    useImperativeHandle(ref, () => ({
      form,
    }));
    useEffect(() => {
      setBoxType(item?.type ? `${item.type}` : '2');
    }, [item]);

    //选择支付方式后渲染
    const renderAfterPayMethod = () => {
      const paymethod = getFieldValue('payMethod');

      //惠市宝
      if (paymethod === '2') {
        return (
          <Form.Item label='费率' style={{ display: '' }}>
            {getFieldDecorator('ruleId', {
              rules: [
                {
                  required: true,
                  message: '请选择费率!',
                },
              ],
              initialValue: item?.ruleId,
            })(
              <Select placeholder='请选择'>
                {ruleList.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.attribute}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        );
      }
      //微企付
      else if (paymethod === '3') {
        return boxType === '2' ? (
          <Form.Item label='商户名称'>
            {getFieldDecorator('entName', {
              rules: [
                {
                  required: true,
                  message: '请输入商户名称!',
                },
              ],
              initialValue: item?.entName,
            })(<Input />)}
          </Form.Item>
        ) : null;
      }
      //汇付天下
      else if (paymethod === '4') {
        return (
          <>
            <Form.Item label='汇付系统号'>
              {getFieldDecorator('sysId', {
                rules: [
                  {
                    message: '请输入汇付系统号!',
                  },
                ],
                initialValue: item?.sysId,
              })(<Input maxLength={100} />)}
            </Form.Item>

            <Form.Item label='汇付产品号'>
              {getFieldDecorator('productId', {
                rules: [
                  {
                    message: '请输入汇付产品号!',
                  },
                ],
                initialValue: item?.productId,
              })(<Input maxLength={100} />)}
            </Form.Item>

            <Form.Item label='汇付私钥'>
              {getFieldDecorator('priKey', {
                rules: [
                  {
                    message: '请输入汇付私钥!',
                  },
                ],
                initialValue: item?.priKey,
              })(<Input.TextArea maxLength={2000} />)}
            </Form.Item>
          </>
        );
      }
    };

    return (
      <div>
        <Modal
          title={title}
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
          confirmLoading={confirmLoading}
          destroyOnClose
        >
          <div className='form-panel'>
            <Form {...layout} autoComplete='off'>
              <Form.Item label='柜台类型'>
                {getFieldDecorator('type', {
                  rules: [
                    {
                      required: true,
                      message: '请选择柜台类型!',
                    },
                  ],
                  initialValue: boxType,
                })(
                  <Radio.Group disabled={!!item} onChange={(e) => setBoxType(e.target.value)}>
                    <Radio value='2'>线上支付</Radio>
                    <Radio value='1'>扫码枪</Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
              <Form.Item label='分行代码'>
                {getFieldDecorator('branchId', {
                  rules: [
                    {
                      message: '请输入分行代码!',
                    },
                  ],
                  initialValue: item?.branchId,
                })(<Input />)}
              </Form.Item>
              <Form.Item label='柜台号'>
                {getFieldDecorator('posId', {
                  rules: [
                    {
                      message: '请输入柜台号!',
                    },
                  ],
                  initialValue: item?.posId,
                })(<Input />)}
              </Form.Item>
              <Form.Item label='商户号'>
                {getFieldDecorator('merchantId', {
                  rules: [
                    {
                      message: '请输入商户号!',
                    },
                  ],
                  initialValue: item?.merchantId,
                })(<Input />)}
              </Form.Item>
              <Form.Item label='商户公钥'>
                {getFieldDecorator('pubKey', {
                  rules: [
                    {
                      message: '请输入商户公钥!',
                    },
                  ],
                  initialValue: item?.pubKey,
                })(<Input.TextArea />)}
              </Form.Item>
              <Form.Item label='支付方式'>
                {getFieldDecorator('payMethod', {
                  rules: [
                    {
                      required: true,
                      message: '请选择支付方式!',
                    },
                  ],
                  initialValue: item?.payMethod,
                })(
                  <Select placeholder='请选择'>
                    <Select.Option key={'1'} value={'1'}>
                      龙支付
                    </Select.Option>
                    <Select.Option key={'2'} value={'2'}>
                      惠市宝
                    </Select.Option>
                    <Select.Option key={'3'} value={'3'} disabled={boxType === '1'}>
                      微企付
                    </Select.Option>
                    <Select.Option key={'4'} value={'4'}>
                      汇付天下
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>
              {renderAfterPayMethod()}
              {boxType === '1' ? (
                <Form.Item label='收银员'>
                  {getFieldDecorator('cashierUid', {
                    rules: [
                      {
                        required: true,
                        message: '请选择收银员!',
                      },
                    ],
                    initialValue: item?.cashierUid,
                  })(
                    <Select placeholder='请选择'>
                      {cashier.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              ) : null}
              {boxType == '2' ? (
                <div>
                  <i style={{ color: 'red' }}>*</i>{' '}
                  <b>
                    添加线上支付柜台时系统会自动创建【小秘收款助手】，用于统计线上支付收银数据。
                  </b>
                </div>
              ) : null}
            </Form>
          </div>
        </Modal>
      </div>
    );
  },
);

export default Form.create()(Index);
