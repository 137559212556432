// 货品列表
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Form, Table, Button, Row, Col, Input, Select } from 'antd';
import { productList, categoryList } from '../../../../state/action/batch';
import { SALE_TYPE, PACK_KIND_NAME } from '../../../../utils/deviceType';

import './device.less';

const FormItem = Form.Item;
const pageSize = 10;

@connect(
  (state) => {
    return { batch: state.batch };
  },
  {
    productList: productList,
    categoryList: categoryList,
  },
)
@Form.create()
class StallProductList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageNo: 1, // 请求页码
    };
  }

  componentDidMount() {
    this.loadAllData();
  }

  //加载全部数据
  loadAllData() {
    this.getProductList({ pageNo: this.state.pageNo, pageSize: pageSize });
    this.getCategoryList();
  }

  async getCategoryList() {
    await this.props.categoryList({ stallId: this.props.stallId });
  }

  async getProductList(params) {
    this.setState({ loading: true });
    params.stallId = this.props.stallId;
    if (!params.saleType) {
      params.saleType = this.props.saleType === 3 ? 1 : this.props.saleType;
    }
    await this.props.productList(params);
    this.setState({ loading: false, pageNo: params.pageNo });
  }

  // 重置按钮
  handleReset = () => {
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      categoryId: undefined,
      saleType: this.props.saleType === 3 ? 1 : this.props.saleType,
    });
    this.loadAllData();
  };

  // 查询
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      // 查询
      this.getProductList({ ...fieldsValue, pageNo: 1, pageSize: pageSize });
    });
  };

  /**
   * 切换页面
   * @param {*} obj
   */
  pageSwitch = (obj) => {
    this.setState({ pageNo: obj.current }, () => {
      this.props.form.validateFields((errors, fieldsValue) => {
        if (!!errors) {
          return;
        }
        // 查询
        this.getProductList({ ...fieldsValue, pageNo: obj.current, pageSize: pageSize });
      });
    });
  };

  render() {
    const { loading, pageNo } = this.state;
    const {
      batch: { productList = {}, categoryList = [] },
    } = this.props;

    const { getFieldDecorator } = this.props.form;
    const { pageList = [], totalSize = 1 } = productList;
    const pagination = {
      current: pageNo,
      pageSize,
      total: totalSize,
    };
    const layout = { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 };

    const columns = [
      {
        title: '货品名称',
        dataIndex: 'productName',
      },
      {
        title: '包装类型',
        dataIndex: 'goodsKindType',
        render: (text) => {
          return PACK_KIND_NAME.get(text) || '';
        },
      },
      {
        title: '售卖方式',
        dataIndex: 'saleType',
        render: (text) => {
          return SALE_TYPE.get(text) || '';
        },
      },
      {
        title: '分类名称',
        dataIndex: 'categoryName',
        render: (text, record) => {
          if (record.saleType === 2) {
            return text ? text : '未分类';
          }
          return text ? text : '';
        },
      },
    ];

    return (
      <div className='basketSettings'>
        <div>
          <Form layout='inline' onSubmit={this.handleSearch}>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem label='货品名称'>
                  {getFieldDecorator('productName', {
                    rules: [{ required: false, message: '请输入货品名称!' }],
                  })(<Input placeholder='请输入货品名称' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='售卖方式'>
                  {getFieldDecorator('saleType', {
                    initialValue: this.props.saleType === 3 ? 1 : this.props.saleType,
                    rules: [{ required: false, message: '请选择售卖方式' }],
                  })(
                    <Select
                      placeholder='请选择售卖方式'
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {[...SALE_TYPE].map(([key, value]) =>
                        key !== 3 ? (
                          <Select.Option key={key} value={key}>
                            {value}
                          </Select.Option>
                        ) : null,
                      )}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='分类'>
                  {getFieldDecorator('categoryId', {
                    rules: [{ required: false, message: '请选择分类' }],
                  })(
                    <Select
                      placeholder='请选择分类'
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option key={0} value={0}>
                        {'未分类'}
                      </Select.Option>
                      {categoryList.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                    重置
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
        <Table
          rowKey={(_, index) => index}
          dataSource={pageList}
          columns={columns}
          loading={loading}
          pagination={pagination}
          onChange={this.pageSwitch}
        />
      </div>
    );
  }
}

export default StallProductList;
