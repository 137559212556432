// 查看详情-合同管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  Button,
  Input,
  message,
  Table,
  Modal,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  Select,
} from 'antd';
import {
  getListBasket,
  addBasket,
  updateBasket,
  configBasket,
} from '../../../../state/action/stall';
import { activeBasket } from '../../../../state/request/stall';

import './device.less';

const FormItem = Form.Item;

const layoutSearch = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
const layout = { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { getListBasket },
)
@Form.create()
class BasketSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      record: {},
      content: '',
      editType: -1, //-1添加,1修改筐子名称, 2修改筐子单价
      defaultCurrent: 1,
      btnStatus: false,
      searchName: '', //搜索名称
      searchStatus: null, //搜索状态 空全部 true启用 false禁用
    };
  }

  componentDidMount() {
    this.getListBasket({
      stallId: this.props.stallId,
    });
    this.configBasket({ stallId: this.props.stallId });
  }

  async getListBasket(params) {
    this.setState({ loading: true });
    await this.props.getListBasket(params);
    this.setState({ loading: false });
  }
  async configBasket(params) {
    const result = await configBasket(params);
    if (result.code === 200) {
      if (result.data === true) {
        this.setState({
          btnStatus: true,
        });
      } else {
        this.setState({
          btnStatus: false,
        });
      }
    } else {
    }
  }

  //点击新增和编辑Modal弹框
  showModal = (record, visible, content, editType) => {
    this.setState({
      record,
      visible,
      content,
      editType,
    });
  };

  // 新增, 修改
  addPact = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        const { record } = this.state;

        if (JSON.stringify(record) === '{}') {
          //新增
          const params = {
            stallId: this.props.stallId,
            basketName: value.basketName,
            basketUnitPrice: value.basketUnitPrice,
            sort: value.sort,
          };

          this.addBasket(params);
        } else {
          const params = {
            stallId: this.props.stallId,
            basketName: value.basketName,
            basketUnitPrice: value.basketUnitPrice,
            sort: value.sort,
            basketType: record.basketType, //筐子类型当ID用
          };
          this.updateBasket(params);
        }
      }
    });
  };

  async addBasket(params) {
    const result = await addBasket(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('新增成功');
      this.getListBasket({
        //刷新列表
        stallId: this.props.stallId,
      });
    } else {
    }
  }

  async updateBasket(params) {
    const result = await updateBasket(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('编辑成功');
      this.getListBasket({
        //刷新列表
        stallId: this.props.stallId,
      });
    } else {
    }
  }

  //启用/禁用筐子
  activeBasket = async (e, record) => {
    e.preventDefault();
    const result = await activeBasket({
      stallId: this.props.stallId,
      basketType: record.basketType, //筐子类型当ID用
      active: !record.active,
    });
    const { code, msg } = result;
    if (code === 200) {
      message.success(`${record.active ? '禁用' : '启用'}成功`);
      this.getListBasket({
        //刷新列表
        stallId: this.props.stallId,
      });
    } else {
      Modal.info({
        content: msg,
        onOk() {},
      });
    }
  };

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      searchName: '',
      searchStatus: null,
    });
    this.getListBasket({
      stallId: this.props.stallId,
    });
  };

  //搜索
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }

      const { searchName = '', searchStatus } = fieldsValue;

      //前端搜索改变过滤条件即可
      this.setState({ searchName: searchName.trim(), searchStatus });
    });
  };

  //过滤筐子
  filterBakset = (allBakset) => {
    let result = allBakset;
    const { searchName, searchStatus } = this.state;

    if (searchName) {
      result = result.filter(
        (item) => item.basketName.toLowerCase().indexOf(searchName.toLowerCase()) != -1,
      );
    }

    if (searchStatus) {
      const status = 'true' === searchStatus;
      result = result.filter((item) => item.active === status);
    }

    return result;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { record, visible, content, loading, editType } = this.state;
    const {
      stall: { listBasket },
    } = this.props;

    const dataSource = this.filterBakset(listBasket || []);
    const columns = [
      {
        title: '筐子名称',
        dataIndex: 'basketName',
        key: 'basketName',
      },
      {
        title: '筐子单价',
        dataIndex: 'basketUnitPrice',
        key: 'basketUnitPrice',
        render: (text) => {
          return text + '元/个';
        },
      },
      {
        title: '筐子状态',
        dataIndex: 'active',
        key: 'active',
        render: (text) => {
          return text ? '启用' : '禁用';
        },
      },
      {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        render: (_, record) => {
          const { active } = record;
          return (
            <span>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showModal(record, true, '修改筐子名称', 0);
                }}
              >
                修改筐子
              </a>
              <Popconfirm
                title={`确定${active ? '禁用' : '启用'}当前筐子吗？`}
                onConfirm={(e) => this.activeBasket(e, record, false)}
                okText='确认'
                cancelText='取消'
              >
                <a
                  href='/'
                  style={{ marginLeft: '10px', color: `${active ? '#EC634A' : '#1D9E60'}` }}
                >
                  {active ? '禁用' : '启用'}
                </a>
              </Popconfirm>
            </span>
          );
        },
      },
    ];

    const yearsArr = [];
    for (let i = 1; i <= 100; i++) {
      yearsArr.push(i);
    }
    return (
      <div className='basketSettings'>
        {this.state.btnStatus === true ? (
          <Form layout='inline' onSubmit={this.handleSearch}>
            <Row gutter={24}>
              <Col {...layoutSearch}>
                <FormItem label='筐子名称'>
                  {getFieldDecorator('searchName', {
                    rules: [{ required: false, message: '请输入筐子名称!' }],
                  })(<Input placeholder='请输入筐子名称' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layoutSearch}>
                <FormItem label='筐子状态'>
                  {getFieldDecorator('searchStatus', {
                    initialValue: 0,
                    rules: [{ required: false, message: '请选择状态' }],
                  })(
                    <Select placeholder='请选择状态'>
                      <Select.Option key={1} value={0}>
                        全部
                      </Select.Option>
                      <Select.Option key={2} value={'true'}>
                        启用
                      </Select.Option>
                      <Select.Option key={3} value={'false'}>
                        禁用
                      </Select.Option>
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...layoutSearch}>
                <FormItem>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    style={{ marginLeft: '20px' }}
                    type='primary'
                    icon='plus'
                    onClick={() => this.showModal({}, true, '添加筐子')}
                  >
                    添加筐子
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        ) : null}

        {dataSource.length === 0 ? null : (
          <Table
            rowKey={(record) => record.basketName}
            dataSource={dataSource}
            columns={columns}
            loading={loading}
          />
        )}
        {visible ? (
          <Modal
            title={content}
            visible={visible}
            onCancel={() => this.showModal({}, false, '')}
            footer={null}
            className={'deviceModalStyle basketSettingsModal'}
            width='750px'
            style={{ maxHeight: 700 }}
          >
            <Form layout='inline' onSubmit={this.addPact}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='筐子名称'>
                    {getFieldDecorator('basketName', {
                      initialValue: record.basketName || '',
                      rules: [{ required: true, message: '请输入筐子名称' }],
                    })(<Input maxLength={4} style={{ width: '200px' }} allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='筐子单价'>
                    {getFieldDecorator('basketUnitPrice', {
                      initialValue: record.basketUnitPrice || '0',
                      rules: [{ required: true, message: '请输入筐子单价' }],
                    })(
                      <InputNumber
                        min={0}
                        max={999}
                        step={1}
                        style={{ width: '150px' }}
                        allowClear
                        disabled={editType === 1 ? true : false}
                      />,
                    )}
                    元/个
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='排序'>
                    {getFieldDecorator('sort', {
                      initialValue: record.sort || '0',
                      rules: [{ required: true, message: '请输入排序' }],
                    })(
                      <InputNumber
                        min={0}
                        max={999999}
                        step={1}
                        style={{ width: '150px' }}
                        allowClear
                      />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.showModal({}, false, '')}>关闭</Button>
                <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default BasketSettings;
