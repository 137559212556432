import request from '../../utils/request';
import { API_STALL } from '../../utils/urls';

// 获取所有的基础配置无分页
export async function getConfigKeys(params) {
  return request({ url: 'configKey/getConfigKeys', params });
}

// 获取配置分组列表
export async function getConfigGroup(params) {
  return request({ url: 'stallConfigGroup/getAllStallConfigGroup', params });
}

// 修改或新增配置
export async function saveOrUpdateConfigGroup(params) {
  return request({ url: 'stallConfigGroup/saveOrUpdateStallConfigGroup', params });
}

// 获取省市区信息
export async function getAllDistrict(params) {
  return request({ url: 'area/getAllDistrict', params });
}

// 获取市场列表
export async function getMarketList(params) {
  return request({ url: 'market/getAllMarketByCityCode', params });
}
// 获取档口列表
export async function getStallList(params) {
  return request({ url: 'stalls/getSimpleStallsByMarket', params });
}
// 获取收银员列表
//https://rap2.caixm.cn/repository/editor?id=33&mod=176&itf=1211
export async function getCashierList(params) {
  return request({ url: 'cashierResource/listAll', params, headers: 'json', method: 'get' });
}
// 帮收银员手动交账
//https://rap2.caixm.cn/repository/editor?id=33&mod=176&itf=1212
export async function cashierHandle(params) {
  return request({ url: 'cashierResource/handleWork', params, headers: 'json' });
}

// 获取某个档口下的分组配置的列表
export async function getStallConfigGroupById(params) {
  //
  return request({ url: 'stallConfigGroup/getAllStallCalcTypeConfig', params });
}

// 获取所有规则配置
export async function getCalcTypeConfigByStallId(params) {
  return request({ url: 'stallCalcTypeConfig/getCalcTypeConfigByStallId', params });
}

// 新增规则
export async function addStallConfigGroup(params) {
  return request({ url: 'stallConfigGroup/updateStallConfigGroup', params });
}

// 删除规则
export async function removeStallConfigGroup(params) {
  return request({ url: 'stallConfigGroup/deleteStallConfigGroup', params });
}

// 修改规则是否显示
export async function updateStallConfigShowFlag(params) {
  return request({ url: 'stallConfigGroup/updateStallConfigShowFlag', params });
}
/*******************************************压框的相关函数**************************** */

//获得url参数加密的字符串
export async function getEncryptStr(params) {
  return request({ url: 'url/getEncryptStr', params, baseURL: API_STALL, headers: 'form-data' });
}

// 商行详情--添加商行员工账号(支持批量添加)
export async function addStallStaff(params) {
  return request({
    url: 'org/batch/add/stall/employ/account',
    params,
    method: 'post',
    headers: 'json',
  });
}

// 商行详情--编辑商行员工账号
export async function editStallStaff(params) {
  return request({
    url: 'org/employee/update',
    params,
    method: 'post',
    headers: 'json',
  });
}

// 获取能添加的商行列表
export async function getValidStallList(params = {}) {
  return request({
    url: `org/can/add/org/stall/list/${params?.orgId}/${params?.phone}`,
    method: 'get',
    headers: 'json',
  });
}

// 获取商行默认角色列表
export async function getStallRoleList(params) {
  return request({ url: `org/stall/default/role/list`, params, method: 'get', headers: 'json' });
}

// 商行员工列表
export async function getStallStaffList(stallId) {
  return request({ url: `org/stall/employ/list/${stallId}`, method: 'get', headers: 'json' });
}

// 商行下员工启用停用
export async function updateStallStaffStatus(params) {
  return request({ url: `org/update/employ/status`, params, method: 'post', headers: 'json' });
}
