// 查看详情-商行买家
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Input, message, Table, Modal, Row, Col, Icon } from 'antd';
import { requestStallBuyerList } from '../../../../../state/action/stall';

import { getStallBuyerMergeList, updateBuyer } from '../../../../../state/request/stall';

import './index.less';
import classNames from '../../../../../../node_modules/classnames/index';

const FormItem = Form.Item;

const layoutSearch = { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 };
const layout = { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { requestStallBuyerList },
)
@Form.create()
class BuyerList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMergeBuyer: false, //展示合并买家弹框
      searchName: '', //搜索名称
      mergetNameSearch: '', //子名称搜索
      showModifyBuyer: false, //显示
      modifyBuyer: null, //待修改买家对象
    };
  }

  componentDidMount() {
    this.getStallBuyerList();
  }

  //查找字符串(忽略大小写)
  searchStr = (searchKey, targetStr) => {
    if (!searchKey && !targetStr) {
      return true;
    }

    if (targetStr == undefined) {
      targetStr = '';
    }

    if (searchKey == undefined) {
      searchKey = '';
    }

    return targetStr.toLowerCase().indexOf(searchKey.toLowerCase()) != -1;
  };

  //获取商行买家 列表
  async getStallBuyerList() {
    this.setState({ loading: true });
    await this.props.requestStallBuyerList(this.props.stallId);
    this.setState({ loading: false });
  }

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      searchName: '',
    });
    this.getStallBuyerList();
  };

  //搜索商行买家列表
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }

      const { searchName = '' } = fieldsValue;

      //前端搜索: 改变过滤条件即可
      if (searchName) {
        this.setState({ searchName: searchName.trim() });
      } else {
        //搜索全部
        this.getStallBuyerList();
      }
    });
  };

  //从子名称查找
  findFromSubName = (searchName, mergeCustomNameList) => {
    if (mergeCustomNameList.length == 0) {
      return false;
    }

    for (const i in mergeCustomNameList) {
      if (this.searchStr(searchName, mergeCustomNameList[i])) {
        return true;
      }
    }

    return false;
  };

  //过滤数据
  filterList = (allData) => {
    let result = allData;
    const { searchName } = this.state;

    if (searchName) {
      result = result.filter(
        (item) =>
          this.searchStr(searchName, item.buyerName) ||
          this.findFromSubName(searchName, item.mergeCustomNameList),
      );
    }

    return result;
  };

  //修改子买家
  modifySubBuyerList = async (e, buyerUid) => {
    e.preventDefault();
    const { code, data } = await getStallBuyerMergeList(this.props.stallId, buyerUid);
    if (code == 200) {
      this.setState({
        showMergeBuyer: true,
        mergetNameSearch: '',
        mergeBuyerList: data,
      });
    }
  };

  //修改买家
  modifyBuyer = async (e, buyer) => {
    e.preventDefault();
    this.setState({
      showModifyBuyer: true,
      modifyBuyer: buyer,
    });
  };

  //子名称弹框搜索
  mergetSubName = (mergetNameSearch) => {
    this.setState({
      mergetNameSearch,
    });
  };

  //保存买家
  saveBuyer = async (e) => {
    e.preventDefault();

    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }

      const { modifyBuyer } = this.state;
      if (!modifyBuyer) {
        message.error('请刷新页面后重试');
        return;
      }

      //修改买家
      const stallId = this.props.stallId;
      const { buyerUid } = modifyBuyer;
      const { buyerName = '', phone } = fieldsValue;
      this.submitSave({ stallId, buyerUid, buyerName, phone });
    });
  };

  //提交保存
  submitSave = async (params) => {
    const { code } = await updateBuyer(params);
    if (code == 200) {
      message.info('已修改');
      this.setState({ showMergeBuyer: false, showModifyBuyer: false, modifyBuyer: null });

      //刷新买家列表
      this.handleReset();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      showMergeBuyer,
      showModifyBuyer,
      modifyBuyer,
      loading,
      mergetNameSearch = '',
      mergeBuyerList = [],
    } = this.state;

    const {
      stall: { stallBuyerList = [] },
    } = this.props;

    const dataSource = this.filterList(stallBuyerList);

    const dataSource4MergeBuyerList = mergeBuyerList
      ? mergeBuyerList.filter((item) => this.searchStr(mergetNameSearch, item.buyerName))
      : mergeBuyerList;

    //buyerName、buyerUid、mergeCustomNameList

    const columns = [
      {
        title: '买家名称',
        dataIndex: 'buyerName',
        key: 'buyerName',
        width: 200,
      },
      {
        title: '子买家',
        dataIndex: 'mergeCustomNameList',
        key: 'mergeCustomNameList',
        render: (_, record) => {
          return record.mergeCustomNameList.length > 0
            ? record.mergeCustomNameList.join('、')
            : '--';
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        width: 160,
        render: (_, record) => {
          const enableEdit = record.mergeCustomNameList.length > 0;

          return (
            <span
              className={classNames('action', { 'update-disable': enableEdit })}
              onClick={(e) => {
                if (enableEdit) {
                  this.modifySubBuyerList(e, record.buyerUid);
                }
              }}
            >
              {enableEdit ? <a>{'修改子买家'}</a> : '修改子买家'}
            </span>
          );
        },
      },
    ];

    //子买家列表弹框
    const columns2 = [
      {
        title: '买家名称',
        dataIndex: 'buyerName',
        key: 'buyerName',
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        width: 100,
        render: (_, record) => {
          return (
            <span>
              <a href='/' onClick={(e) => this.modifyBuyer(e, record)}>
                修改
              </a>
            </span>
          );
        },
      },
    ];

    const yearsArr = [];
    for (let i = 1; i <= 100; i++) {
      yearsArr.push(i);
    }
    return (
      <div className='buyerList'>
        <Form layout='inline' onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...layoutSearch}>
              <FormItem label='买家名称'>
                {getFieldDecorator('searchName', {
                  rules: [{ required: false, message: '请输入买家名称!' }],
                })(<Input placeholder='请输入买家名称' allowClear />)}
              </FormItem>
            </Col>
            <Col {...layoutSearch}>
              <FormItem>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
                <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                  重置
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>

        {dataSource.length === 0 ? null : (
          <Table
            rowKey={(record) => record.buyerUid}
            dataSource={dataSource}
            columns={columns}
            loading={loading}
          />
        )}

        {/* 子买家列表弹框 */}
        {showMergeBuyer ? (
          <Modal
            title={'修改子买家'}
            visible={true}
            onCancel={() => {
              this.setState({
                showMergeBuyer: false,
                mergeBuyerList: [],
                mergetNameSearch: '',
              });
            }}
            footer={null}
            className={'buyerModalStyle'}
            width='750px'
            style={{ maxHeight: 700 }}
          >
            <FormItem label='子买家名称' style={{ display: 'flex' }}>
              <Input
                placeholder='请输入买家名'
                allowClear
                onChange={(e) => this.mergetSubName(e.target.value.trim())}
                style={{ width: '400px' }}
                prefix={<Icon type='search' style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </FormItem>

            <Table
              rowKey={(record) => record.buyerUid}
              dataSource={dataSource4MergeBuyerList}
              columns={columns2}
            />
          </Modal>
        ) : null}

        {/* 修改买家弹框 */}
        {showModifyBuyer && modifyBuyer ? (
          <Modal
            title={'修改子买家'}
            visible={true}
            onCancel={() => {
              this.setState({ showModifyBuyer: false, modifyBuyer: null });
            }}
            footer={null}
            className={'buyerModalStyle'}
            width='750px'
            style={{ maxHeight: 700 }}
          >
            <Form layout='inline' onSubmit={this.saveBuyer}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='子买家名称'>
                    {getFieldDecorator('buyerName', {
                      initialValue: modifyBuyer.buyerName || '',
                      rules: [{ required: true, message: '请输入子买家名称' }],
                    })(<Input maxLength={24} style={{ width: '400px' }} allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row gutter={12}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='电话'>
                    {getFieldDecorator('phone', {
                      initialValue: modifyBuyer.phone || '',
                      rules: [{ required: false, message: '请输入电话!' }],
                    })(<Input placeholder='请输入电话' style={{ width: '400px' }} allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    this.setState({
                      showModifyBuyer: false,
                      modifyBuyer: null,
                    });
                  }}
                >
                  关闭
                </Button>
                <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default BuyerList;
