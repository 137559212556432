import moment from 'moment';
import { message } from 'antd';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function fixedZeroTo4Bit(val, len) {
  let length = (val + '').length;
  let format = '';
  for (var i = len; i > length; i--) {
    format += '0';
  }
  return format + val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'pre') {
    return moment(new Date(new Date().getTime() - oneDay)).format('YYYY-MM-DD ');
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟'],
  ];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(num * 10 * 10 ** index) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    let isAdd = false;
    // 是否包含
    isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path,
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some((route) => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  return reg.test(path);
}

export function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}

// value - id || city - parent_id
// 调用时，传入数据和当前节点ID(数据为树型结构)
// let newLevel = getParent(this.info, row.id)
export function getParent(data2, nodeId2, children, id, parent_id) {
  var arrRes = [];
  if (data2.length === 0) {
    if (!!nodeId2) {
      arrRes.unshift(data2);
    }
    return arrRes;
  }
  let rev = (data, nodeId) => {
    for (var i = 0, length = data.length; i < length; i++) {
      let node = data[i];
      if (node[id] === nodeId) {
        arrRes.unshift(node);
        rev(data2, node[parent_id]);
        break;
      } else {
        if (!!node[children]) {
          rev(node[children], nodeId);
        }
      }
    }
    return arrRes;
  };
  arrRes = rev(data2, nodeId2);
  return arrRes;
}
export const strSplit = (str, len) => {
  if (!str) {
    str = '';
  } else if (str.length > len) {
    str = str.substring(0, len) + '...';
  }
  return str;
};

// 解决 eval(this.sevenDateValueList.join('+')   // eval can be harmful
export function evil(fn) {
  let Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn('return ' + fn)();
}

/**
 * 金额的正则表达式
 */
export const regAmount = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
/**
 * 判断是否是金额
 */
export function isAmount(value) {
  if (new RegExp(regAmount).test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 导出文件
 * @param {Blob} response
 * @param {string} fileName
 * @returns
 */
export function exportFile(response, fileName) {
  if (!response || (response && response.code && response.code !== 200)) {
    message.warning('导出失败！');
    return;
  }
  if (!response.size) {
    message.warning('暂无数据！');
    return;
  }
  let blob = new Blob([response]); //response.data为后端传的流文件
  let downloadFilename = fileName || '1.xlsx'; //设置导出的文件名
  if (response.type === 'application/vnd.ms-excel') {
    downloadFilename = downloadFilename.replace('.xlsx', '.xls');
  }

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //兼容ie浏览器
    window.navigator.msSaveOrOpenBlob(blob, downloadFilename);
  } else {
    //谷歌,火狐等浏览器
    let url = window.URL.createObjectURL(blob);
    let downloadElement = document.createElement('a');
    downloadElement.style.display = 'none';
    downloadElement.href = url;
    downloadElement.download = downloadFilename;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(url);
  }
  // self.$message({ type: "success", message: '导出成功！' });
  message.info('导出成功！');
}

// 获取不同版本金额
/**
 * 1: 蔬菜
 * 2: 水果
 * 3: 水产
 */

/**
 * 5: 基础版
 * 2: 专业版
 * 6: 旗舰版
 * 7: 标准版
 * 8: 便捷版
 */

const depositData = {
  // 蔬菜
  1: {
    5: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
    7: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    2: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    6: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    8: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
  },
  2: {
    5: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
    7: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
    2: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    6: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    8: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
  },
  3: {
    5: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
    7: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    2: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    6: {
      1: 3000,
      2: 5500,
      3: 7200,
    },
    8: {
      1: 2000,
      2: 4000,
      3: 6000,
    },
  },
};

export function getDeposit(businessType, type, year) {
  let deposit = 0;
  if (depositData[businessType] && depositData[businessType][type]) {
    deposit = depositData[businessType][type][year];
  }
  return deposit;
}

//POS同时在线数
export function getPosOnlineNum() {
  return 1;
}

//默认员工数： 便捷版(8)3个，基础版（5）、标准版（7） 5个、 专业版（2）、旗舰版（6） 无限制

const accountNumDefaultMap = new Map([
  [8, 3],
  [5, 5],
  [7, 5],
  [2, 0],
  [6, 0],
]);
export function getDefaultEmployNum(stallType) {
  return accountNumDefaultMap.get(stallType);
}
